import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  count: 0,
};
const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    incCount: (state, action) => {
      state.count += 1;
    },
    decCount: (state, action) => {
      state.count -= 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { incCount, decCount, setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
