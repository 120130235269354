import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axiosClient";
import { notifier } from "../../../common/sharedComponents/qbStrap";
import { setLoading } from "./loadingSlice";

const errorTrackerInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getErrorTracker = createAsyncThunk(
  "errorTracker/getErrorTracker",
  (payload) => {
    return axiosClient.post("/server/errortracker", payload).then((res) => {
      return res;
    });
  }
);

export const getErrorEvent = createAsyncThunk(
  "errorTracker/getErrorEvent",
  (payload) => {
    return axiosClient.post("/server/errorevents", payload).then((res) => {
      return res;
    });
  }
);

export const updateErrorStatus = createAsyncThunk(
  "errorTracker/updateErrorStatus",
  (payload) => {
    return axiosClient
      .post("/server/updateerror", payload)
      .then((res) => {
        res?.status
          ? notifier(
            "success",
            res?.message || "Error status updated successfully"
          )
          : notifier(
            "error",
            res?.message || "Error Occurred, status failed to update"
          );
        return res;
      })
      .catch((err) =>
        notifier(
          "error",
          err?.message || "Error Occurred, status failed to update"
        )
      );
  }
);

const errorTrackerSlice = createSlice({
  name: "errorTracker",
  initialState: errorTrackerInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getErrorTracker.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getErrorTracker.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getErrorTracker.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const redisKeysInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getRedisKeys = createAsyncThunk(
  "redisKeys/getRedisKeys",
  (payload) => {
    return axiosClient.post("/server/rediskeys", payload).then((res) => {
      return res;
    });
  }
);

export const viewKeyDetails = createAsyncThunk(
  "redisKeys/viewKeyDetails",
  (payload) => {
    return axiosClient.post("/server/viewkeydetails", payload).then((res) => {
      return res;
    });
  }
);

const redisKeysSlice = createSlice({
  name: "redisKeys",
  initialState: redisKeysInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getErrorTracker.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getErrorTracker.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getErrorTracker.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const failedJobsInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const fetchFailedJobsList = createAsyncThunk(
  "failedJobs/fetchFailedJobsList",
  (payload) => {
    return axiosClient
      .post("/server/failedjobs", payload)
      .then((res) => {
        !res?.data && notifier("error", res?.message || "Cannot fetch data");
        return res;
      })
      .catch((err) => notifier("error", err?.message || "Cannot fetch data"));
  }
);

export const failedJobDetails = createAsyncThunk(
  "failedJobs/failedJobDetails",
  (payload) => {
    return axiosClient
      .post("/server/failedjobdetails", payload)
      .then((res) => {
        !res?.data && notifier("error", res?.message || "Cannot fetch details");
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Cannot fetch details")
      );
  }
);

export const dispatchJobs = createAsyncThunk(
  "failedJobs/dispatchJobs",
  (payload) => {
    return axiosClient
      .post("/server/dispatchJobs", payload)
      .then((res) => {
        res?.status
          ? notifier(
            "success",
            res?.message || "Selected Jobs has been pushed back to queue"
          )
          : notifier("error", res?.message || "Some error Occured");
        return res;
      })
      .catch((err) => notifier("error", err?.message || "Some error Occured"));
  }
);

const failedJobsSlice = createSlice({
  name: "failedJobs",
  initialState: failedJobsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFailedJobsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFailedJobsList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchFailedJobsList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const statsInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getFeatureCategoryStatistics = createAsyncThunk(
  "categoryStats/getFeatureCategoryStatistics",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post("/server/categorystats", payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getFeatureStatistics = createAsyncThunk(
  "categoryStats/getFeatureStatistics",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post(
        "/server/featurestatistics",
        payload
      );
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getApiStatistics = createAsyncThunk(
  "categoryStats/getApiStatistics",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post("/server/apistatitics", payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const categoryStatsSlice = createSlice({
  name: "statistics",
  initialState: statsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatureCategoryStatistics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeatureCategoryStatistics.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getFeatureCategoryStatistics.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const schemaInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getSchemaRoutes = createAsyncThunk(
  "schema/getSchemaRoutes",
  async (payload, { dispatch }) => {
    // await dispatch(setLoading(true));
    try {
      const response = await axiosClient.get("/server/schemaroutes");
      // dispatch(setLoading(false));
      return response;
    } catch (error) {
      console.log(error);
      // dispatch(setLoading(false));
      return error.message;
    }
  }
);

export const getOTP = createAsyncThunk(
  "schema/getOTP",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post(
        "/server/raiseotprequest",
        payload
      );
      response.status
        ? notifier("success", response?.message || "OTP has been sent")
        : notifier("error", response?.message || "Some error occurred");
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "schema/verifyOtp",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post("/server/verifyotp", payload);
      response.status
        ? notifier("success", response?.message || "OTP is Verified")
        : notifier("error", response?.message || "Enter correct OTP");
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchSettingList = createAsyncThunk(
  "schema/fetchSettingList",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.get("/server/settinglist");
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchIndustryList = createAsyncThunk(
  "schema/fetchIndustryList",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.get("/server/industrylist");
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchWarehouseList = createAsyncThunk(
  "schema/fetchWarehouseList",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.get("/server/warehouselist");
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchStoreList = createAsyncThunk(
  "schema/fetchStoreList",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.get("/server/storelist");
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const createSchema = createAsyncThunk(
  "schema/createSchema",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post(
        "/server/createschemaroute",
        payload
      );
      response.status
        ? notifier("success", response?.message)
        : notifier("error", response?.message || "Something went wrong");
      return response;
    } catch (error) {
      notifier("error", error?.message || "Something went wrong");
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchSchemaTracker = createAsyncThunk(
  "schema/fetchSchemaTracker",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post("/server/schematracker", payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchSchemaHistory = createAsyncThunk(
  "schema/fetchSchemaHistory",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post("/server/schemahistory", payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const disableSchemaRoute = createAsyncThunk(
  "schema/disableSchemaRoute",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post("/server/disableschema", payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const schemaSlice = createSlice({
  name: "schema",
  initialState: schemaInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchemaRoutes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSchemaRoutes.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getSchemaRoutes.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const otpLogsInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getOTPLogs = createAsyncThunk(
  "otplogs/getOTPLogs",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosClient.post("/server/otplogs", payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const otpLogsSlice = createSlice({
  name: "otplogs",
  initialState: otpLogsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOTPLogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOTPLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getOTPLogs.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const schemaNavigationInitialState = {
  data: [],
}

const schemaNavigationSlice = createSlice({
  name: "schemaNavigation",
  initialState: schemaNavigationInitialState,
  reducers: {
    navigateTo: (state, action) => {
      console.log("---action---", action)
      state.data = action.payload;
    },
  },
});

const permissionInitialState = {
  data: [],
  error: ""
}

export const createPermission = createAsyncThunk(
  "permission/createPermission",
  (payload) => {
    return axiosClient.post("/server/createpermission", payload).then((res) => {
      res?.status ? notifier("success", res?.data?.message) : notifier("error", res?.data?.message)
      return res;
    }).catch((err) => notifier("error", err?.message || "Something went wrong"))
  }
);

export const createNewPayment = createAsyncThunk(
  "permission/createNewPayment",
  (payload) => {
    return axiosClient.post("/server/paymentaccount", payload).then((res) => {
      res?.status ? notifier("success", res?.data?.message) : notifier("error", res?.data?.message)
      return res;
    }).catch((err) => notifier("error", err?.message || "Something went wrong"))
  }
)

const permissionSlice = createSlice({
  name: "permission",
  initialState: permissionInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPermission.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(createPermission.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});



export const errorTracker = errorTrackerSlice.reducer;
export const redisKeys = redisKeysSlice.reducer;
export const failedJobs = failedJobsSlice.reducer;
export const statistics = categoryStatsSlice.reducer;
export const schema = schemaSlice.reducer;
export const otplogs = otpLogsSlice.reducer;
// export const permission = permissionSlice.reducer;
export const { navigateTo } = schemaNavigationSlice.actions;
export default schemaNavigationSlice.reducer;
export const permission = permissionSlice.reducer;