import React, { Suspense } from "react";
import { useTheme } from "./common/ThemeContext.js";
import { BrowserRouter } from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import SuspenseLoader from "./common/sharedComponents/Suspense.jsx";
import { checkLoginStatus } from "./app/redux/Slices/authSlice.js";
import "./App.scss";
import ErrorBoundary from "./features/layout/ErrorBoundry.jsx";

const App = () => {
  const dispatch = useDispatch();

  const { theme } = useTheme();

  const AppHelper = React.lazy(() => Promise.all([
    import('./AppHelper.jsx'), // The Component to be loaded
    new Promise(resolve => setTimeout(resolve,1000)), // Setting minimum time to load the AppHelper component to show the loader component
  ]).then(([moduleExports]) => moduleExports));
  
  React.useEffect(() => {
    dispatch(checkLoginStatus()); // Updating the login status in Redux state before loading the AppHelper component
  }, []);

  return (
    // <React.StrictMode>
      <ErrorBoundary>
      <div id={theme}>
        <BrowserRouter>
          <Suspense
            children={<AppHelper />}
            fallback={<SuspenseLoader />}
            /> 
        </BrowserRouter>
      </div>
      </ErrorBoundary>
    // </React.StrictMode>
  );
};
export default App;
