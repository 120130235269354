import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axiosClient";
import { notifier } from "../../../common/sharedComponents/qbStrap";

const smsRateInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getSmsRates = createAsyncThunk("smsRates/getSmsRates", () => {
  return axiosClient.get("/thirdparty/smschainrate").then((res) => {
    return res;
  });
});

export const updateSMSRate = createAsyncThunk(
  "smsRates/updateSMSRate",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updatesmsrate", payload)
      .then((res) => {
        if (res?.status) {
          notifier("success", res?.message || "SMS Rate Updated");
        } else if (!res?.status) {
          notifier("error", res?.message || "Failed to Update SMS Rate");
        }
        return res;
      });
  }
);

const smsRateSlice = createSlice({
  name: "smsRates",
  initialState: smsRateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSmsRates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSmsRates.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getSmsRates.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const appSettingInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getAppSetting = createAsyncThunk(
  "appSetting/getAppSetting",
  () => {
    return axiosClient.get("/thirdparty/globalsettings").then((res) => {
      return res;
    });
  }
);

export const updateAppSetting = createAsyncThunk(
  "appSetting/updateAppSetting",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updateglobalappsettings", payload)
      .then((res) => {
        if (res?.status) {
          notifier("success", res?.message || "App Setting Updated");
        } else if (!res?.status) {
          notifier("error", res?.message || "Failed to Update App Setting");
        }
        return res;
      });
  }
);

const appSettingSlice = createSlice({
  name: "appSetting",
  initialState: appSettingInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppSetting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppSetting.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getAppSetting.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const smsRates = smsRateSlice.reducer;
export const appSetting = appSettingSlice.reducer;
