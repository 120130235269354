import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../axiosClient";

let countriesListInitialState={
    loading:false,
    data:[],
    error:""
}

export const fetchCountriesList =  createAsyncThunk("setup/getCountriesList",(payload)=>{
    return axiosClient.get("/setup/countryList", payload).then((res)=>{
        return res;
    })
})

const countryListSlice = createSlice({
    name: "countries",
    initialState: countriesListInitialState,
    reducers: {},
    extraReducers: (builder)=>{
        builder.addCase(fetchCountriesList.pending, (state)=>{
            state.loading=true;
        })
        builder.addCase(fetchCountriesList.fulfilled, (state,action)=>{
            state.loading=false;
            state.data= action.payload.statusCode == 200 ? action.payload.countryList : [];
            state.error='';
        })
        builder.addCase(fetchCountriesList.rejected, (state,action)=>{
            state.loading=false;
            state.data= [];
            state.error="Error in Api";
        })
    }
})

const countries = countryListSlice.reducer;


let resellerListInitialState={
    loading:false,
    data:{},
    error:""
}

export const fetchResellersList =  createAsyncThunk("setup/getResellersList",(payload)=>{
    return axiosClient.get("/setup/getResellersList", payload).then((res)=>{
        return res;
    })
})

const resellersListSlice = createSlice({
    name: "resellers",
    initialState: resellerListInitialState,
    reducers: {},
    extraReducers: (builder)=>{
        builder.addCase(fetchResellersList.pending, (state)=>{
            state.loading=true;
        })
        builder.addCase(fetchResellersList.fulfilled, (state,action)=>{
            state.loading=false;
            state.data= action.payload;
            state.error='';
        })
        builder.addCase(fetchResellersList.rejected, (state,action)=>{
            state.loading=false;
            state.data= [];
            state.error="Error in Api";
        })
    }
})

const resellers = resellersListSlice.reducer;

export default {resellers,countries};