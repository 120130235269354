import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axiosClient";
import { notifier } from "../../../common/sharedComponents/qbStrap";
import { setLoading } from "./loadingSlice";

export const getFilteredLicenses = createAsyncThunk(
  "license/getFilteredLicenses",
  (payload) => {
    return axiosClient
      .post(`/license/getFilteredLicenses`, payload)
      .then((res) => {
        return res;
      });
  }
);

const licenseRateInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getLicenseRate = createAsyncThunk(
  "licenseRate/getLicenseRate",
  () => {
    return axiosClient.get("/thirdparty/getlicenserate").then((res) => {
      return res;
    });
  }
);

export const updateLicenseRate = createAsyncThunk(
  "licenseRate/updateLicenseRate",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updatelicenserate", payload)
      .then((res) => {
        res?.status
          ? notifier(
              "success",
              res?.message || "License rate updates successfully"
            )
          : notifier(
              "error",
              res?.message?.["userID"]?.[0] ||
                res?.message?.["chainID"]?.[0] ||
                res?.message?.["ID"]?.[0] ||
                res?.message?.["mPlan1"]?.[0] ||
                res?.message?.["mPlan2"]?.[0] ||
                res?.message?.["yPlan1"]?.[0] ||
                res?.message?.["yPlan2"]?.[0] ||
                res?.message?.["hPlan1"]?.[0] ||
                res?.message?.["hPlan2"]?.[0] ||
                res?.message?.["fivePlan"]?.[0] ||
                "Unable to update license rate"
            );
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Unable to update license rate")
      );
  }
);

export const extendLicenses = createAsyncThunk(
  "licenseRate/extendLicenses",
  (payload) => {
    return axiosClient
      .post("/license/extendLicenses", payload)
      .then((res) => {
        res?.status ?
        notifier("success", res?.message || "Licenses extended successfully") :
        notifier("error", res?.message || "Unable to extend licenses")
        return res;
      })
      .catch((err) => notifier("error", err?.message || "Unable to extend license"));
  }
);

export const fetchGlobalLicenses = createAsyncThunk(
  "licenseRate/fetchGlobalLicenses",
  (payload) => {
    return axiosClient
      .post("/license/fetchGlobalLicenses", payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {});
  }
);

export const saveGlobalPayment = createAsyncThunk(
  "licenseRate/saveGlobalPayment",
  (payload) => {
    return axiosClient
      .post("/license/saveGlobalPayment", payload)
      .then((res) => {
        res?.status ? notifier('success', res?.message || "Payment made successfully") : notifier('error', res?.message || "Unable to make payment")
        return res;
      })
      .catch((e) => notifier('error', e?.message || "Unable to make payment"));
  }
);

const licenseRateSlice = createSlice({
  name: "licenseRate",
  initialState: licenseRateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLicenseRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLicenseRate.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getLicenseRate.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const licenseTransferInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const transferLicense = createAsyncThunk(
  "licensetransfer/transferLicense",
  (payload) => {
    return axiosClient.post("/license/licensetransfer", payload).then((res) => {
      // res?.status
      //   ? notifier("success", res?.message || "License transferred successfully")
      //   : notifier("error", res?.message || "Unable to transfer license");
      return res;
    }
    ).catch((err) => {
      // notifier("error", err?.message || "Unable to transfer license");
      return err;
    });
  }
);

const licenseTransferSlice = createSlice({
  name: "licenseTransfer",
  initialState: licenseTransferInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(transferLicense.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(transferLicense.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(transferLicense.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const licenserateInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const fetchlicenseRate = createAsyncThunk(
  "chainlicenserate/fetchlicenseRate",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = axiosClient.post("/license/chainlicenserate", payload);
      return response;
    } catch (err) {
      throw err;
    } finally {
      dispatch(setLoading);
    }
  }
);

export const licenseRateUpdation = createAsyncThunk(
  "licenseRates/licenseRateUpdation",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = axiosClient.post("/license/updatelicense", payload);
      return response;
    } catch (err) {
      throw err;
    } finally {
      dispatch(setLoading);
    }
  }
);

const licenseRatesSlice = createSlice({
  name: "licenseRates",
  initialState: licenserateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchlicenseRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchlicenseRate.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchlicenseRate.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const getPaymentHistory = createAsyncThunk("global/getPaymentHistory", (payload) => {
  return axiosClient.post("/license/getPaymentHistory", payload).then((res) => {
     return res;
  });
});


const licenseSuspensionInitialState = {
  data: [],
  loading: false
}

export const suspendLicense = createAsyncThunk(
  "licenceSuspend/suspendLicense",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = axiosClient.post("/license/licenseSuspension", payload);
      return response;
    } catch (err) {
      throw err;
    } finally {
      dispatch(setLoading);
    }
  }
)

export const suspendedLicenseList =  createAsyncThunk(
  "licenceSuspend/suspendedLicenseList",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = axiosClient.get("/license/suspensionRequestLicenses", payload);
      return response;
    } catch (err) {
      throw err;
    } finally {
      dispatch(setLoading);
    }
  }
)

export const approveLicSuspensionRequest =  createAsyncThunk(
  "licenceSuspend/approveLicSuspensionRequest",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = axiosClient.post("/license/approveSuspensionRequest", payload);
      return response;
    } catch (err) {
      throw err;
    } finally {
      dispatch(setLoading);
    }
  }
)

const licenseSuspensionSlice = createSlice({
  name: "licenceSuspend",
  initialState: licenseSuspensionInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(suspendLicense.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(suspendLicense.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(suspendLicense.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const resellerLicense = createAsyncThunk("license/resellerLicense", (payload) => {
  return axiosClient.get("/license/resellerLicense", payload).then((res) => {
    return res;
  })
  .catch((err) => notifier('error', err.message || "Some error occured"))
});


export const licenseRate = licenseRateSlice.reducer;
export const licenseTransfer = licenseTransferSlice.reducer;
export const licenseRates = licenseRatesSlice.reducer;
export const licenceSuspend = licenseSuspensionSlice.reducer;