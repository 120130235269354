import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axiosClient";
import { notifier } from "../../../common/sharedComponents/qbStrap";
import { setLoading } from "./loadingSlice";

const superChainInitialState = {
  loading: false,
  data: [],
  error: "",
};

const packagesInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getSuperChain = createAsyncThunk(
  "superchains/getSuperChain",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getsuperchainlist", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      })
      .catch((err) => console.log("--err--", err));
  }
);

export const getSuperChainStoresMapList = createAsyncThunk(
  "superchains/getSuperChainStoresMapList",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getSuperChainStoresMapList", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

export const getSuperChainBrandsMapList = createAsyncThunk(
  "superchains/getSuperChainBrandsMapList",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getSuperChainBrandsMapList", payload)
      .then((res) => {
        !res?.status && notifier("error", res?.message || "Unable to fetch super chain brands");
        if(res?.data?.length === 0) {
          payload?.thirdPartyChainID !== 'ALL' && notifier("error", "No brands are mapped with this superchain");
        }
        return res;
      })
      .catch((err) => notifier("error", err?.message));
  }
);

export const getSuperChainDevicesMapList = createAsyncThunk(
  "superchains/getSuperChainDevicesMapList",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getSuperChainDevicesMapList", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

export const getGlobalFeaturePackage = createAsyncThunk(
  "superchains/getGlobalFeaturePackage",
  () => {
    return axiosClient.get("/thirdparty/globalfeaturepackage").then((res) => {
      return res?.data ? res.data : [];
    });
  }
);

export const updateSuperchain = createAsyncThunk(
  "superchains/updateSuperchain",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updatethirdpartychain", payload)
      .then((res) => {
        res?.status
          ? notifier(
            "success",
            res?.message || "Third party chain updated successfully"
          )
          : notifier("error", res?.message || "Some problem occurred");
        return res?.data ? res : [];
      })
      .catch((err) => notifier("error", err?.message));
  }
);

export const unMapSuperUserDevice = createAsyncThunk(
  "adminChains/unMapSuperUserDevice",
  (payload) => {
    return axiosClient
      .post("/thirdparty/unMapSuperUserDevice", payload)
      .then((res) => {
        res?.status ? notifier('success', res?.message) : notifier('error', res?.message)
        return res ? res : [];
      })
      .catch((err) => notifier('error', err?.message))
  }
);

export const superUserDeviceMappingBulk = createAsyncThunk(
  "adminChains/superUserDeviceMappingBulk",
  (payload) => {
    return axiosClient
      .post("/thirdparty/superUserDeviceMappingBulk", payload)
      .then((res) => {
        res?.status ? notifier('success', "Device successfully mapped") : notifier('error', res?.message)
        return res ? res : [];
      })
      .catch((err) => notifier('error', err?.message))
  }
);

export const superUserDeviceMapping = createAsyncThunk(
  "adminChains/superUserDeviceMapping",
  (payload) => {
    return axiosClient
      .post("/thirdparty/superUserDeviceMapping", payload)
      .then((res) => {
        res?.status ? notifier('success', res?.message) : notifier('error', res?.message)
        return res ? res : [];
      })
      .catch((err) => notifier('error', err?.message))
  }
);

export const fetchStoreDevice = createAsyncThunk(
  "adminChains/fetchStoreDevice",
  (payload) => {
    return axiosClient
      .post("/thirdparty/fetchStoreDevice", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

export const superUserChainMapping = createAsyncThunk(
  "adminChains/superUserChainMapping",
  (payload) => {
    return axiosClient
      .post("/thirdparty/superUserChainMapping", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

export const fetchGlobalQBFeaturePackages = createAsyncThunk(
  "packages/fetchGlobalQBFeaturePackages",
  (payload) => {
    return axiosClient
      .post("/thirdparty/fetchGlobalQBFeaturePackages", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

export const createPackage = createAsyncThunk(
  "packages/createPackage",
  (payload) => {
    return axiosClient
      .post("/thirdparty/createPackage", payload)
      .then((res) => {
        res?.status ? notifier("success", res?.message) : notifier("error", res?.message)
        return res ? res : [];
      })
      .catch((err) => notifier("error", err?.message))
  }
);

export const updatePackage = createAsyncThunk(
  "packages/updatePackage",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updatePackage", payload)
      .then((res) => {
        res?.status ? notifier("success", res?.message) : notifier("error", res?.message)
        return res ? res : [];
      })
      .catch((err) => notifier("error", err?.message))
  }
);

export const deletePackage = createAsyncThunk(
  "packages/deletePackage",
  (payload) => {
    return axiosClient
      .post("/thirdparty/deletePackage", payload)
      .then((res) => {
        res?.status ? notifier("success", res?.message) : notifier("error", res?.message)
        return res ? res : [];
      })
      .catch((err) => notifier("error", err?.message))
  }
);

const packageSlice = createSlice({
  name: "packages",
  initialState: packagesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalQBFeaturePackages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGlobalQBFeaturePackages.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchGlobalQBFeaturePackages.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});
export const getSuperchainDetail = createAsyncThunk(
  "superchains/getSuperchainDetail",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getsuperchaindetails", payload)
      .then((res) => {
        return res;
      });
  }
);

const superChainSlice = createSlice({
  name: "superchains",
  initialState: superChainInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuperChain.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSuperChain.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getSuperChain.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

let newSuperchainInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const createNewSuperChain = createAsyncThunk(
  "newSuperchains/createNewSuperChain",
  (payload) => {
    return axiosClient
      .post("/thirdparty/createsuperchain", payload)
      .then((res) => {
        res?.status
          ? notifier("success", res?.message || "New third party chain created")
          : notifier("error", res?.message || "Some Problem occurred");
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Some Problem occurred")
      );
  }
);

const newSuperChainSlice = createSlice({
  name: "newSuperchains",
  initialState: newSuperchainInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewSuperChain.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewSuperChain.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(createNewSuperChain.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

let thirdPartyClientsInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getThirdPartyClients = createAsyncThunk(
  "thirdPartyClients/getThirdPartyClients",
  async (payload, { dispatch }) => {
    try {
      const response = await axiosClient.get("/thirdparty/getthirdpartyclients");
      console.log("---res---", response)
      !response.status && notifier("error", response?.message || "Unable to fetch third party clients");
      return response;
    } catch (err) {
      notifier("error", err?.message || "Unable to fetch third party clients");
      throw err;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const thirdPartyClientsSlice = createSlice({
  name: "thirdPartyClients",
  initialState: thirdPartyClientsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getThirdPartyClients.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getThirdPartyClients.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getThirdPartyClients.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

let thirdPartyChainInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getChainList = createAsyncThunk(
  "thirdPartyChainList/getChainList",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getthirdpartychainlist", payload)
      .then((res) => {
        return res;
      });
  }
);

const thirdPartyChainSlice = createSlice({
  name: "thirdPartyChainList",
  initialState: thirdPartyChainInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChainList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getChainList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getChainList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

let createTPCInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const addNewTPClient = createAsyncThunk(
  "newThirdPartyClient/addNewTPClient",
  async (payload, { dispatch }) => {
    try {
      const response = await axiosClient.post(
        "/thirdparty/addnewthirdpartyclient",
        payload
      );
      response.status ? notifier('success', response.message || "New thirdparty client added") : notifier('error', response.message || "Some error occurred")
      return response;
    } catch (err) {
      throw err;
    } finally {
    }
  }
);

const tpcSlice = createSlice({
  name: "newThirdPartyClient",
  initialState: createTPCInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewTPClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNewTPClient.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(addNewTPClient.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

let licenseListInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const fetchLicenseList = createAsyncThunk(
  "licenseList/fetchLicenseList",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getlicenselist", payload)
      .then((res) => {
        return res;
      });
  }
);

export const getLicensePaymentHistory = createAsyncThunk(
  "licenseList/getpaymentHistory",
  (payload) => {
    return axiosClient
      .post("/thirdparty/licensepaymentlist", payload)
      .then((res) => {
        return res;
      });
  }
);

export const fetchActiveLicenses = createAsyncThunk(
  "licenseList/fetchActiveLicenses",
  (payload) => {
    return axiosClient
      .post("/thirdparty/activelicenses", payload)
      .then((res) => {
        console.log("-----res-----", res);
        return res;
      });
  }
);

export const fetchExpiringLicenses = createAsyncThunk(
  "licenseList/fetchExpiringLicenses",
  (payload) => {
    return axiosClient
      .post("/thirdparty/expiringlicenses", payload)
      .then((res) => {
        return res;
      });
  }
);

const licenseSlice = createSlice({
  name: "licenseList",
  initialState: licenseListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLicenseList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLicenseList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchLicenseList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const getChainsDetailedList = createAsyncThunk(
  "chains/getChainsDetailedList",
  (payload) => {
    return axiosClient
      .post("/master/getChainsDetailedList", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

export const  generateParkedLicense = createAsyncThunk(
  "chains/generateParkedLicense",
  (payload) => {
    return axiosClient
      .post("/master/generateParkedLicense", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);


export const  renewQBCreditsOffline = createAsyncThunk(
  "chains/renewQBCreditsOffline",
  (payload) => {
    return axiosClient
      .post("/master/renewQBCreditsOffline", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

export const purchaseSMSWhatsAppCredits = createAsyncThunk(
  "chains/purchaseSMSWhatsAppCredits",
  (payload) => {
    return axiosClient
      .post("/master/purchaseSMSWhatsAppCredits", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);


let chainsDetailedListState = {
  loading: false,
  error: "",
};

const getChainsDetailedListSlice = createSlice({
  name: "chainsDetailedList",
  initialState: chainsDetailedListState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChainsDetailedList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getChainsDetailedList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(getChainsDetailedList.rejected, (state, action) => {
      state.loading = false;
      state.error = "Error in Getting Chains Detailed List!";
    });
  },
});

export const getChainDetail = createAsyncThunk(
  "chains/chainDetail",
  (payload) => {
    return axiosClient.post("/master/chainDetail", payload).then((res) => {
      return res?.data ? res.data : [];
    });
  }
);

let chainDetailInitialState = {
  loading: false,
  error: "",
};

const chainDetailSlice = createSlice({
  name: "chainDetail",
  initialState: chainDetailInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChainDetail.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getChainDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload ? action.payload[0] : {};
    });
    builder.addCase(getChainDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = "Error in Getting Chain Detail!";
    });
  },
});

export const getIndustryList = createAsyncThunk(
  "chains/getIndustries",
  (payload) => {
    return axiosClient.get(`/master/getIndustries`, payload).then((res) => {
      return res?.data ? res.data : [];
    });
  }
);

export const getChainPackages = createAsyncThunk(
  "chains/packagesList",
  (payload) => {
    return axiosClient
      .post(`/master/chainPackagesList`, payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);
export const getChainModulesList = createAsyncThunk(
  "chains/chainModulesList",
  (payload) => {
    return axiosClient.post(`/master/chainModulesList`, payload).then((res) => {
      return res?.modules ? res.modules : [];
    });
  }
);
export const updateChainModulesList = createAsyncThunk(
  "chains/updateModulesList",
  (payload) => {
    return axiosClient
      .post(`/master/updateModulesList`, payload)
      .then((res) => {
        return res;
      });
  }
);

export const updateChainHotSetting = createAsyncThunk(
  "chains/updateChainHotSetting",
  (payload) => {
    return axiosClient
      .put(`/master/updateChainHotSetting`, payload)
      .then((res) => {
        return res;
      });
  }
);

export const getStoresList = createAsyncThunk(
  "stores/getStoresList",
  (payload) => {
    return axiosClient.post(`/master/getStoresList`, payload).then((res) => {
      return res;
    });
  }
);

export const createStore = createAsyncThunk("stores/createStore", (payload) => {
  return axiosClient.post(`/master/createStore`, payload).then((res) => {
    return res;
  });
});

export const saveChainSettings = createAsyncThunk(
  "stores/saveChainSettings",
  (payload) => {
    return axiosClient
      .post(`/master/saveChainSettings`, payload)
      .then((res) => {
        return res;
      });
  }
);

export const saveStoreSettings = createAsyncThunk(
  "stores/saveStoreSettings",
  (payload) => {
    return axiosClient
      .post(`/master/saveStoreSettings`, payload)
      .then((res) => {
        return res;
      });
  }
);

export const fixStockLevelForStores = createAsyncThunk(
  "stores/fixStockLevelForStores",
  (payload) => {
    return axiosClient.post(`/master/stockLevel`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      notifier('error', err?.message)
    })
  }
)

// Detailed Store List
let getStoresListInitialState = {
  loading: false,
  error: "",
};
const getStoresListSlice = createSlice({
  name: "chainModulesList",
  initialState: getStoresListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStoresList.pending, (state) => {
      state.loading = true;
      state.error = "";
      state.data = [];
    });
    builder.addCase(getStoresList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(getStoresList.rejected, (state, action) => {
      state.loading = false;
      state.error = "Error in Getting Stores List!";
      state.data = [];
    });
  },
});

let chainModulesInitialState = {
  loading: false,
  error: "",
};

const chainModulesListSlice = createSlice({
  name: "chainModulesList",
  initialState: chainModulesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChainModulesList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getChainModulesList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(getChainModulesList.rejected, (state, action) => {
      state.loading = false;
      state.error = "Error in Getting Chain Modules List!";
    });
  },
});

let industriesListInitialState = {
  loading: false,
  error: "",
  data: [],
};

const industriesListSlice = createSlice({
  name: "chainDetail",
  initialState: industriesListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIndustryList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getIndustryList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(getChainDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = "Error in Getting Industry List!";
    });
  },
});

let brandInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const fetchAdminChainList = createAsyncThunk(
  "chainList/fetchAdminChainList",
  (payload) => {
    return axiosClient
      .post("/thirdparty/fetchAdminChainList", payload)
      .then((res) => {
        return res;
      });
  }
);

const adminChainSlice = createSlice({
  name: "chainList",
  initialState: brandInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdminChainList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdminChainList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAdminChainList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});
const adminChainsState = {
  loading: false,
  data: [],
  error: "",
};

const userInitialState = {
  loading: false,
  data: [],
  error: "",
};

const deviceListInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const syncDevice = createAsyncThunk(
  "deviceList/syncDevice",
  (payload) => {
    return axiosClient.post("/thirdparty/syncdevice", payload).then((res) => {
      return res;
    });
  }
);
export const forceSyncAction = createAsyncThunk(
  "chains/forceSync",
  (payload) => {
    return axiosClient.post("/master/forceSync", payload).then((res) => {
      return res;
    });
  }
);

export const extendToken = createAsyncThunk(
  "deviceList/extendToken",
  (payload) => {
    return axiosClient.post("/thirdparty/extendtoken", payload).then((res) => {
      res?.status ? notifier("success", res?.message || "Token extended") : notifier("error", res?.message || "Failed to extend token");
      return res;
    })
      .catch((err) => notifier("error", err?.message || "Failed to extend token"));
  }
);

const paymentConfigurationInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getBrandList = createAsyncThunk(
  "paymentConfiguration/getBrandList",
  (payload) => {
    return axiosClient.post("/thirdparty/brandlist", payload).then((res) => {
      return res;
    });
  }
);

export const fetchDeviceConfigurations = createAsyncThunk(
  "paymentConfiguration/fetchDeviceConfigurations",
  async (payload, { dispatch }) => {
    try {
      const response = await axiosClient.post(
        "/thirdparty/deviceconfigurations",
        payload
      );
      return response;
    } catch (error) {
      throw error;
    } finally {
    }
  }
);

export const fetchChainPaymentTypes = createAsyncThunk(
  "paymentConfiguration/fetchChainPaymentTypes",
  (payload) => {
    return axiosClient
      .post(`/thirdparty/chainpaymenttypes`, payload)
      .then((res) => {
        if (!res?.status) {
          notifier(
            "error",
            res?.message || "Unable to fetch chain payment types"
          );
        }
        return res;
      });
  }
);

export const createDevicePayemntConfig = createAsyncThunk(
  "paymentConfiguration/createDevicePayemntConfig",
  async (payload, { dispatch }) => {
    try {
      const response = await axiosClient.post(`/thirdparty/createdevicepayconfig`, payload);
      response.status ? notifier("success", response?.message || "Device payment configuration created successfully") :
        notifier("error", response?.message || "Failed to create device payment configuration")
      return response;
    } catch (error) {
      throw error;
    } finally {
      // dispatch(stopLoading());
    }
  }
);

export const deleteDevicePayemntConfig = createAsyncThunk(
  "paymentConfiguration/deleteDevicePayemntConfig",
  (payload) => {
    return axiosClient
      .post(`/thirdparty/deletedevicepayconfig`, payload)
      .then((res) => {
        res?.status ? notifier("success", res?.message || "Device payment configuration deleted successfully") :
          notifier("error", res?.message || "Failed to delete device payment configuration")
        return res;
      });
  }
);
export const updateDevicePaymentConfig = createAsyncThunk(
  "paymentConfiguration/updateDevicePaymentConfig",
  (payload) => {
    return axiosClient
      .post(`/thirdparty/updatedevicepayconfig`, payload)
      .then((res) => {
        res?.status ? notifier("success", res?.message || "Device payment configuration updated successfully") :
          notifier("error", res?.message || "Failed to update device payment configuration")
        return res;
      });
  }
);

const devicePayConfigurationSlice = createSlice({
  name: "paymentConfiguration",
  initialState: paymentConfigurationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceConfigurations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeviceConfigurations.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchDeviceConfigurations.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const fetchChainStores = createAsyncThunk(
  "adminChains/fetchChainStores",
  (payload) => {
    return axiosClient
      .post("/thirdparty/fetchChainStores", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

const chainStoresListInitialState = {
  loading: false,
  data: [],
  error: "",
};

const chainStoresListSlice = createSlice({
  name: "chainStoresList",
  initialState: chainStoresListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChainStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchChainStores.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchChainStores.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const chainMappedStores = chainStoresListSlice.reducer;

export const fetchMultipleStoreDevices = createAsyncThunk(
  "adminChains/fetchMultipleStoreDevices",
  (payload) => {
    return axiosClient
      .post("/thirdparty/fetchMultipleStoreDevices", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => notifier('error', err.message || "Some error occurred"))
  }
);

export const setBrandToSuperChain = createAsyncThunk(
  "adminChains/setBrandToSuperChain",
  (payload) => {
    return axiosClient
      .post("/thirdparty/setBrandToSuperChain", payload)
      .then((res) => {
        res?.status ? notifier("success", res?.message) : notifier("error", res?.message)
        return res ? res : [];
      });
  }
);

export const fetchMappedSuperChain = createAsyncThunk(
  "adminChains/fetchMappedSuperChain",
  (payload) => {
    return axiosClient
      .post("/thirdparty/fetchMappedSuperChain", payload)
      .then((res) => {
        return res?.data ? res.data : [];
      });
  }
);

const adminChainsSlice = createSlice({
  name: "adminChains",
  initialState: adminChainsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdminChainList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdminChainList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAdminChainList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const fetchBrandList = createAsyncThunk(
  "brandList/fetchBrandList",
  (payload) => {
    return axiosClient.post("/thirdparty/getbrandlist", payload).then((res) => {
      return res;
    });
  }
);
const brandSlice = createSlice({
  name: "brandList",
  initialState: brandInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBrandList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchBrandList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const fetchUserList = createAsyncThunk(
  "userList/fetchUserList",
  (payload) => {
    return axiosClient.post("/thirdparty/getuserslist", payload).then((res) => {
      return res;
    });
  }
);
export const updateUser = createAsyncThunk("userList/updateUser", (payload) => {
  return axiosClient
    .post("/thirdparty/updateuserdetail", payload)
    .then((res) => {
      return res;
    });
});
export const disableUser = createAsyncThunk(
  "userList/disableUser",
  (payload) => {
    return axiosClient.post("/thirdparty/disableuser", payload).then((res) => {
      return res;
    });
  }
);
const userSlice = createSlice({
  name: "userList",
  initialState: userInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchUserList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const fetchDeviceList = createAsyncThunk(
  "deviceList/fetchDeviceList",
  (payload) => {
    return axiosClient
      .post("/thirdparty/fetchdevicelist", payload)
      .then((res) => {
        return res;
      });
  }
);
export const resetDevice = createAsyncThunk(
  "deviceList/resetDevice",
  (payload) => {
    return axiosClient.post("/thirdparty/resetdevice", payload).then((res) => {
      res?.status ? notifier('success', res?.response || "Device is reset") : notifier('error', res?.response || "Unable to reset device")
      return res;
    });
  }
);
const deviceListSlice = createSlice({
  name: "deviceList",
  initialState: deviceListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeviceList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchDeviceList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const activeMerchantsInitialRate = {
  loading: false,
  data: [],
  error: "",
};

export const getActiveMerchants = createAsyncThunk(
  "merchants/activeMerchants",
  (payload) => {
    return axiosClient
      .post("/thirdparty/activemerchants", payload)
      .then((res) => {
        return res;
      });
  }
);

const merchantsSlice = createSlice({
  name: "merchants",
  initialState: activeMerchantsInitialRate,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActiveMerchants.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActiveMerchants.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getActiveMerchants.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const chainLicenseRateInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getchainLicenseList = createAsyncThunk(
  "licenseRate/getchainLicenseList",
  (payload) => {
    return axiosClient.post("/thirdparty/chainlist", payload).then((res) => {
      return res;
    });
  }
);

export const getLicenseDetails = createAsyncThunk(
  "licenseRate/getLicenseDetails",
  (payload) => {
    return axiosClient
      .post("/thirdparty/globalchainlicenserate", payload)
      .then((res) => {
        return res;
      });
  }
);

export const createChain = createAsyncThunk("master/createChain", (payload) => {
  return axiosClient.post("/master/createChain", payload).then((res) => {
    return res;
  });
});
export const getSuperChainPackages = createAsyncThunk("master/getSuperChainPackages", (payload) => {
  return axiosClient.post("/master/getSuperChainPackages", payload).then((res) => {
    return res;
  });
});

export const updateLicenseRate = createAsyncThunk(
  "licenseRate/updateLicenseRate",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updateglobalchainlicenserate", payload)
      .then((res) => {
        res?.status
          ? notifier("success", res?.message || "Rate updated successfully")
          : notifier("error", res?.message || "Failed to update");
        return res;
      })
      .catch((err) => notifier("error", err?.message || "Failed to update"));
  }
);

const licenseRateSlice = createSlice({
  name: "licenseRate",
  initialState: chainLicenseRateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLicenseDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLicenseDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getLicenseDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const promotionsInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getPromotions = createAsyncThunk(
  "promotions/getPromotions",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getglobalpromotions", payload)
      .then((res) => {
        if (!res?.status) {
          notifier("error", res?.message || "Failed to get promotions");
        }
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to get promotions")
      );
  }
);

export const addPromotions = createAsyncThunk(
  "promotions/addPromotions",
  (payload) => {
    return axiosClient
      .post("/thirdparty/addglobalpromotions", payload)
      .then((res) => {
        if (res?.status) {
          notifier("success", res?.message || "Promotion added successfully");
        } else {
          notifier("error", res?.message || "Failed to add promotion");
        }
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to add promotion")
      );
  }
);

export const updatePromotions = createAsyncThunk(
  "promotions/updatePromotions",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updateglobalpromotions", payload)
      .then((res) => {
        if (res?.status) {
          notifier("success", res?.message || "Promotion updated successfully");
        } else {
          notifier("error", res?.message || "Failed to update promotion");
        }
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to update promotion")
      );
  }
);

export const deletePromotions = createAsyncThunk(
  "promotions/deletePromotions",
  (payload) => {
    return axiosClient
      .post("/thirdparty/deleteglobalpromotions", payload)
      .then((res) => {
        if (res?.status) {
          notifier("success", res?.message || "Promotion deleted successfully");
        } else {
          notifier("error", res?.message || "Failed to delete promotion");
        }
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to delete promotion")
      );
  }
);

const promotionsSlice = createSlice({
  name: "promotions",
  initialState: promotionsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPromotions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPromotions.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getPromotions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const getGlobalCustomers = createAsyncThunk(
  "global/getGlobalCustomers",
  (payload) => {
    return axiosClient
      .get("/master/getGlobalCustomers", payload)
      .then((res) => {
        return res;
      });
  }
);

export const getCustomerOrders = createAsyncThunk(
  "global/getCustomerOrders",
  (payload) => {
    return axiosClient
      .post("/master/getCustomerOrders", payload)
      .then((res) => {
        return res;
      });
  }
);

const thirdpartyIntegrationInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getThirdpartyIntegration = createAsyncThunk(
  "thirdpartyIntegration/getThirdpartyIntegration",
  (payload) => {
    return axiosClient
      .post("/thirdparty/getthirdpartyintegration", payload)
      .then((res) => {
        Boolean(!res?.data.length) && notifier("error", "No data available")
        return res;
      })
      .catch((err) => notifier("error", err?.message || "Failed to get third party integration"));
  }
);

export const addThirdPartyClient = createAsyncThunk(
  "thirdpartyIntegration/addThirdPartyClient",
  (payload) => {
    return axiosClient
      .post("/thirdparty/activateTPI", payload)
      .then((res) => {
        if (res?.status) {
          notifier(
            "success",
            res?.message || "Third party client added successfully"
          );
        } else {
          notifier("error", res?.message || "Failed to add third party client");
        }
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to add third party client")
      );
  }
);

const thirdpartyIntegrationSlice = createSlice({
  name: "thirdpartyIntegration",
  initialState: thirdpartyIntegrationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getThirdpartyIntegration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getThirdpartyIntegration.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getThirdpartyIntegration.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const pricinginitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getGlobalPricing = createAsyncThunk(
  "pricing/getGlobalPricing",
  () => {
    return axiosClient
      .get("/thirdparty/countrywisepricing")
      .then((res) => {
        res?.statusCode !== 200 &&
          notifier(
            "error",
            res?.message || "Failed to get country wise pricing"
          );
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to get country wise pricing")
      );
  }
);

const pricingSlice = createSlice({
  name: "pricing",
  initialState: pricinginitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGlobalPricing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGlobalPricing.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getGlobalPricing.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const jarvisUsersInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getJarvisUsers = createAsyncThunk(
  "jarvisUsers/getJarvisUsers",
  () => {
    return axiosClient
      .get("/thirdparty/jarvisusers")
      .then((res) => {
        res?.status
          ? console.log("--res--", res?.status)
          : notifier("error", res?.message || "Failed to fetch Jarvis users");
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to fetch Jarvis users")
      );
  }
);

export const createJarvisUser = createAsyncThunk(
  "jarvisUsers/createJarvisUser",
  (payload) => {
    return axiosClient
      .post("/thirdparty/createjarvisuser", payload)
      .then((res) => {
        res?.status
          ? notifier("success", res?.message || "User created successfully")
          : notifier("error", res?.message || "Failed to create user");
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to create user")
      );
  }
);

export const deleteJarvisUser = createAsyncThunk(
  "jarvisUsers/deleteJarvisUser",
  (payload) => {
    return axiosClient
      .post("/thirdparty/deletejarvisuser", payload)
      .then((res) => {
        res?.status
          ? notifier("success", res?.message || "User deleted successfully")
          : notifier("error", res?.message || "Failed to delete user");
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to delete user")
      );
  }
);

export const updateJarvisUser = createAsyncThunk(
  "jarvisUsers/updateJarvisUser",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updatejarvisuserpermissions", payload)
      .then((res) => {
        res?.status
          ? notifier(
            "success",
            res?.message || "User's permission updated successfully"
          )
          : notifier(
            "error",
            res?.message || "Failed to update user's permission"
          );
        return res;
      })
      .catch((err) =>
        notifier("error", err?.message || "Failed to update user's permission")
      );
  }
);

const jarvisSlice = createSlice({
  name: "jarvisUsers",
  initialState: jarvisUsersInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJarvisUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJarvisUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getJarvisUsers.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const chainStatsInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const getChainStats = createAsyncThunk(
  "chainStats/getChainStats",
  (payload) => {
    return axiosClient
      .post("/thirdparty/chainstatistics", payload)
      .then((res) => {
        !res?.status && notifier('error', res?.message || 'Some error occurred')
        return res;
      })
      .catch((err) => notifier('error', err?.message))
  }
);

const chainStatsSlice = createSlice({
  name: "chainStats",
  initialState: chainStatsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChainStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getChainStats.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getChainStats.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const jarvisPermissionInitialState = {
  loading: false,
  data: [],
  error: "",
};

export const fetchJarvisPermissions = createAsyncThunk(
  "jarvisPermission/fetchJarvisPermissions",
  () => {
    return axiosClient
      .get("/thirdparty/jarvispermissions")
      .then((res) => {
        return res;
      });
  }
);

export const userPermissions = createAsyncThunk(
  "jarvisPermission/userPermissions",
  (payload) => {
    return axiosClient
      .post("/thirdparty/jarvisUserPermissions", payload)
      .then((res) => {
        return res;
      });
  }
)

const jarvisPermissionSlice = createSlice({
  name: "jarvisPermission",
  initialState: jarvisPermissionInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJarvisPermissions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchJarvisPermissions.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchJarvisPermissions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});


const chainMerchantUpdateInitialState = {
  data: []
}

export const fetchMerchantSegment = createAsyncThunk(
  "chainMerchant/fetchMerchantSegment",
  () => {
    return axiosClient
      .get("/thirdparty/merchantsegments")
      .then((res) => {
        return res;
      });
  }
)

export const updateMerchantSegmentOrigin = createAsyncThunk(
  "chainMerchant/updateMerchantSegmentOrigin",
  (payload) => {
    return axiosClient
      .post("/thirdparty/updateMerchantSegmentAndOrigin", payload)
      .then((res) => {
        res?.status ? notifier("success", res?.message) : notifier("error", res?.message)
        return res;
      })
      .catch((err) => notifier("error", err?.message))
  }
)

export const updateLicensePartner = createAsyncThunk(
  "chainMerchant/updateLicensePartner",
  (payload) => {
    debugger
    return axiosClient
      .post("/thirdparty/licensePartner", payload)
      .then((res) => {
        res?.status ? notifier("success", res?.message) : notifier("error", res?.message)
        return res;
      })
      .catch((err) => notifier("error", err?.message))
  }
)

export const getChainIDBysMerchantDetails = createAsyncThunk(
  "chainMerchant/getChainIDBysMerchantDetails",
  (payload) => {
    debugger
    return axiosClient
      .post("/thirdparty/chainIDsByMerchantDetail", payload)
      .then((res) => {
        !res?.status && notifier("error", res?.message)
        return res;
      })
      .catch((err) => notifier("error", err?.message))
  }
)

export const getChainIDsByReseller = createAsyncThunk(
  "chainMerchant/getChainIDsByReseller",
  (payload) => {
    debugger
    return axiosClient
      .post("/thirdparty/chainIDsByReseller", payload)
      .then((res) => {
        !res?.status && notifier("error", res?.message)
        return res;
      })
      .catch((err) => notifier("error", err?.message))
  }
)

const merchantChainSlice = createSlice({
  name: "chainMerchant",
  initialState: chainMerchantUpdateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMerchantSegment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMerchantSegment.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchMerchantSegment.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});


export const superchains = superChainSlice.reducer;
export const newSuperchains = newSuperChainSlice.reducer;
export const thirdPartyClients = thirdPartyClientsSlice.reducer;
export const thirdPartyChainList = thirdPartyChainSlice.reducer;
export const newThirdPartyClient = tpcSlice.reducer;
export const licenseList = licenseSlice.reducer;
export const chainsDetailedList = getChainsDetailedListSlice.reducer;
export const chainDetail = chainDetailSlice.reducer;
export const industriesList = industriesListSlice.reducer;
export const chainModulesList = chainModulesListSlice.reducer;
export const chainList = adminChainSlice.reducer;
export const paymentConfiguration = devicePayConfigurationSlice.reducer;
export const adminChains = adminChainsSlice.reducer;
export const brandList = brandSlice.reducer;
export const userList = userSlice.reducer;
export const deviceList = deviceListSlice.reducer;
export const packageList = packageSlice.reducer;
export const merchants = merchantsSlice.reducer;
export const licenseRate = licenseRateSlice.reducer;
export const detailedStoresList = getStoresListSlice.reducer;
export const promotions = promotionsSlice.reducer;
export const thirdpartyIntegration = thirdpartyIntegrationSlice.reducer;
export const pricing = pricingSlice.reducer;
export const jarvisUsers = jarvisSlice.reducer;
export const chainStats = chainStatsSlice.reducer;
export const jarvisPermission = jarvisPermissionSlice.reducer;
export const chainMerchant = merchantChainSlice.reducer