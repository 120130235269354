import {  createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../axiosClient";

export const getMappedFranchisesList = createAsyncThunk("franchise/mappedFranchisesList",(payload)=>{
    return axiosClient.post(`/franchise/mappedFranchisesList`,payload).then((res)=>{
        return res;
    });
});

const franchiseListInitialState = { loading: false, data: [], error: ""};

const franchiseListSlice = createSlice({
  name: "mappedFranchises",
  initialState: franchiseListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMappedFranchisesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMappedFranchisesList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getMappedFranchisesList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

const mappedFranchises = franchiseListSlice.reducer; 


export const getDistributesList = createAsyncThunk("franchise/distributesList",(payload)=>{
    return axiosClient.get(`/franchise/distributesList`).then((res)=>{
        return res;
    });
});

const distributersListInitialState = { loading: false, data: [], error: ""};

const distributersListSlice = createSlice({
  name: "distributers",
  initialState: distributersListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDistributesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDistributesList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getDistributesList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});


const distributers = distributersListSlice.reducer; 

  //Get Mapped Franchise Stores
export const getMappedFranchiseStoresList = createAsyncThunk("franchise/mappedFranchiseStoresList",(payload)=>{
    return axiosClient.post(`/franchise/mappedFranchiseStoresList`,payload).then((res)=>{
        return res;
    });
});

const mappedFranchiseStoresListInitialState = { loading: false, data: [], error: ""};

const mappedFranchiseStoresListSlice = createSlice({
  name: "distributers",
  initialState: mappedFranchiseStoresListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMappedFranchiseStoresList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMappedFranchiseStoresList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getMappedFranchiseStoresList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});


const mappedFranchiseStores = mappedFranchiseStoresListSlice.reducer; 

  //Delete Franchise 
export const setDeleteFranchise = createAsyncThunk("franchise/setDeleteFranchise",(payload)=>{
    return axiosClient.post(`/franchise/deleteFranchise`,payload).then((res)=>{
        return res;
    });
});

const deleteFranchiseInitialState = { loading: false, data: [], error: ""};

const deleteFranchiseSlice = createSlice({
  name: "deleteFranchise",
  initialState: deleteFranchiseInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDeleteFranchise.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setDeleteFranchise.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(setDeleteFranchise.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});


const deleteFranchise = deleteFranchiseSlice.reducer; 


  //Map  Franchise to Distributer 
export const franchiseDistributerMapping = createAsyncThunk("franchise/franchisedistributermap",(payload)=>{
    return axiosClient.post(`/franchise/franchisedistributermap`,payload).then((res)=>{
        return res;
    });
});

  //Map Store To Franchise Chain 
export const franchiseStoreMapping = createAsyncThunk("franchise/franchisestoremap",(payload)=>{
    return axiosClient.post(`/franchise/franchisestoremap`,payload).then((res)=>{
        return res;
    });
});

const mapStoreToFranchiseInitialState = { loading: false, data: [], error: ""};

const mapStoreToFranchiseSlice = createSlice({
  name: "deleteFranchise",
  initialState: mapStoreToFranchiseInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(franchiseStoreMapping.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(franchiseStoreMapping.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(franchiseStoreMapping.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error in Api";
    });
  },
});

export const getFranchiseOperations = createAsyncThunk("franchiseoperations/getFranchiseOperations",(payload)=>{
  return axiosClient.get(`/franchise/franchiseOperationsData`,payload).then((res)=>{
      return res;
  });
});

const franchiseOpeartionInitailState = { loading: false, data: [], error: ""};

const franchiseOperationSlice = createSlice({
name: "franchiseoperations",
initialState: franchiseOpeartionInitailState,
reducers: {},
extraReducers: (builder) => {
  builder.addCase(getFranchiseOperations.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getFranchiseOperations.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = "";
  });
  builder.addCase(getFranchiseOperations.rejected, (state, action) => {
    state.loading = false;
    state.data = [];
    state.error = "Error in Api";
  });
},
});


const mapStoreToFranchise = mapStoreToFranchiseSlice.reducer; 

  //Update Franchise Blocked Operations 
export const updateFranchiseDetails = createAsyncThunk("franchise/updateFranchise",(payload)=>{
    return axiosClient.post(`/franchise/updateFranchise`,payload).then((res)=>{
        return res;
    });
});

export default {mappedFranchises,distributers,mappedFranchiseStores,deleteFranchise,mapStoreToFranchise, franchiseOperationSlice: franchiseOperationSlice.reducer};