import countries from "../../common/countries";

export const optionCreater = ({ data, label, value }) => {
  // custom function for making options

  let options =
    data &&
    Array.isArray(data) &&
    data.map((el) => {
      let opt = { label: el[label], value: el[value] };
      return opt;
    });
  return options || [];
};

export const updatedDropdownList = ({
  options,
  labelName,
  valueName,
  allOption,
}) => {
  const updatedList = options.length > 0 && options?.map((ele) => {
    return {
      label:
        typeof labelName === "string"
          ? ele[labelName]
          : `${ele?.[labelName?.[0]] + " - " + ele?.[labelName?.[1]]}`,
      value: typeof valueName === "string"
        ? ele[valueName]
        : `${ele?.[valueName?.[0]] + " - " + ele?.[valueName?.[1]]}`,
    };
  });
  {
    allOption && updatedList.unshift({ label: "ALL", value: "ALL" });
  }
  return updatedList || [];
};

export const columnCreater = ({ key, customName }) => {
  // Split the key into words based on uppercase letters
  let words = key.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  // Capitalize the first letter of each word
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words with spaces
  let title = customName ? customName : words.join(" ");

  let column = { title, dataIndex: key, key };
  return column;
};

export const formikValidate = (values, requiredFields) => {
  const errors = {};

  requiredFields &&
    requiredFields.forEach((el) => {
      if (!values[el]) {
        errors[el] = "This Field is Required";
      }
    });

  return errors;
};

export const getCountries = () => {
  const countriesArr = countries?.map((ele) => ({
    label: ele?.name,
    value: ele?.name,
  }));
  return countriesArr;
};

export const getCountryCallingCodes = () => {
  const countryCallingCode = countries?.map((ele) => ({
    label: ele?.name + "  +" + ele?.callingCodes,
    value: parseInt(ele?.callingCodes[0]),
  }));
  return countryCallingCode;
};

export const dateFormater = (date) => {
  const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let d = new Date(date);
  let formattedDate =
    d.getDate() + " " + (monthArr[d.getMonth()]) + ", " + d.getFullYear();
  return formattedDate;
};

export const excelHeaderFormatter = (header) => {
  const camelCaseHeader = header.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
  return camelCaseHeader.replace(/(?!<I|D)([A-Z])/g, ' $1').replace(/^./, str => str.trim());

}
