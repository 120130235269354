import { configureStore } from "@reduxjs/toolkit";
import {
  superchains,
  newSuperchains,
  thirdPartyClients,
  thirdPartyChainList,
  newThirdPartyClient,
  licenseList,
  chainsDetailedList,
  chainDetail,
  industriesList,
  chainModulesList,
  packageList,
  chainMappedStores,
  detailedStoresList,
  jarvisUsers,
  chainStats,
  jarvisPermission,
} from "./Slices/masterDataSlices";
import {
  chainList,
  userList,
  paymentConfiguration,
  adminChains,
  brandList,
} from "./Slices/masterDataSlices";
import {
  errorTracker,
  redisKeys,
  failedJobs,
  statistics,
  otplogs,
} from "./Slices/serverConfigSlice";
import { smsRates, appSetting } from "./Slices/globalSettingsSlice";
import { licenseRate } from "./Slices/licensesSlice";
import authSlice from "./Slices/authSlice";
import { countries } from "./Slices/setupSlice";
import loadingReducer from "./Slices/loadingSlice";
import setupActions from "./Slices/setupSlice";
import franchiseActions from "./Slices/franchiseSlice";
import schemaNavigationReducer from "./Slices/serverConfigSlice";
import resellerSlice from "./Slices/resellerSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    loading: loadingReducer,
    superchains,
    newSuperchains,
    thirdPartyClients,
    thirdPartyChainList,
    newThirdPartyClient,
    licenseList,
    chainsDetailedList,
    chainDetail,
    industriesList,
    chainModulesList,
    chainList,
    adminChains,
    brandList,
    userList,
    paymentConfiguration,
    ...franchiseActions,
    packageList,
    smsRates,
    appSetting,
    licenseRate,
    chainMappedStores,
    detailedStoresList,
    ...setupActions,
    errorTracker,
    redisKeys,
    failedJobs,
    statistics,
    otplogs,
    schemaNavigation: schemaNavigationReducer,
    jarvisUsers,
    chainStats,
    jarvisPermission,
    resellers: resellerSlice,
  },
      
  devTools: true,
});
