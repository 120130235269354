import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axiosClient";
import { notifier } from "../../../common/sharedComponents/qbStrap";

const authInitialState = {
   loading: false,
   data: [],
   error: "",
   isAuthenticated: false,
};

export const login = createAsyncThunk("auth/login",async (payload) => {
   try{
      const res = await axiosClient.post("/auth/login", payload);
      if(!res.status) throw res;
      notifier("success", "Login Success");
      return res;
   }
   catch(err){
      return err.response ? err.response : err;
   }
});

export const checkLoginStatus = createAsyncThunk("auth/checkLoginStatus", (_,{dispatch}) => {
   return axiosClient.get("/auth/loginstatus").then(async (res) => {
      await dispatch(updateState({ data: res, status: res?.status }));
      return res;
   })
   .catch((err) => {
      return err;
   });
});

export const logout = createAsyncThunk("auth/logout", () => {
   return axiosClient.post("/auth/logout").then((res) => {
      res?.success ? console.log("--logiut--") : notifier("error", res?.message || "Logout Failed");
      return res;
   })
   .catch((err) => notifier("error", err?.message || "Logout Failed"));
});

const loginSlice = createSlice({
   name: "auth",
   initialState: authInitialState,
   reducers: {
      updateState: (state, action) => {
         const data = action.payload.data;
         const status = action.payload.data?.status || false;
         state.data = data;
         state.isAuthenticated = status;
      }
   },
   extraReducers: (builder) => {
      builder.addCase(login.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = false;
      });
      builder.addCase(login.fulfilled, (state, action) => {
         state.loading = false;
         state.data = action.payload;
         state.isAuthenticated = action.payload?.status;
         state.error = "";
      });
      builder.addCase(login.rejected, (state, action) => {
         state.loading = false;
         state.data = [];
         state.error = "Error in Api";
         state.isAuthenticated = false;
      });
      builder.addCase(checkLoginStatus.pending, (state) => {
         state.loading = true;
      });
      builder.addCase(checkLoginStatus.fulfilled, (state, action) => {
         state.loading = false;
         state.data = action.payload;
         state.error = "";
      });
      builder.addCase(checkLoginStatus.rejected, (state, action) => {
         state.loading = false;
         state.data = [];
         state.error = "Error in Api";
         state.isAuthenticated = false;
      });
   },
});

export const { updateState } = loginSlice.actions;
export default loginSlice.reducer;
