import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../axiosClient";
import { notifier } from "../../../common/sharedComponents/qbStrap";

export const getResellersList = createAsyncThunk("reseller/list", (payload) => {
   return axiosClient.post("/reseller/resellerslist", payload).then((res) => {
      return res;
   });
});
export const getPartnerPayments = createAsyncThunk("reseller/paymentslist", (payload) => {
   return axiosClient.post("/reseller/partnerpaymentslist", payload).then((res) => {
      return res;
   });
});


const resellerInitialState = {
   data: []
}

const resellerSlice = createSlice({
   name: "reseller",
   initialState: resellerInitialState,
   reducers: {
      editData: (state, action) => {
         state.data = action.payload;
      },
   },
});

export const getPaymentList = createAsyncThunk("reseller/paymentList", (payload) => {
   return axiosClient.post("/reseller/payment", payload).then((res) => {
      return res;
   }).catch((err) => notifier("error", err.message || "Cannot fetch payment list"));
})

export const releasePayment = createAsyncThunk("reseller/releasePayment", (payload) => {
   return axiosClient.post("/reseller/releasepayment", payload).then((res) => {
      return res;
   }).catch((err) => notifier("error", err.message || "Cannot release payment"));
})

export const registerPartner = createAsyncThunk("reseller/registerPartner", (payload) => {
   return axiosClient.post("/reseller/registerpartner", payload).then((res) => {
      res?.status ? notifier("success", "Partner registered successfully") : notifier("error", res?.message || "Cannot register partner");
      return res;
   })
      .catch((err) => notifier("error", err.message || "Cannot register partner"));
});

export const updatePartner = createAsyncThunk("reseller/updatePartner", (payload) => {
   return axiosClient.post("/reseller/updatepartner", payload).then((res) => {
      res?.status ? notifier("success", "Partner updated successfully") : notifier("error", res?.message || "Cannot update partner");
      return res;
   })
      .catch((err) => notifier("error", err.message || "Cannot update partner"));
});

export const { editData } = resellerSlice.actions;
export default resellerSlice.reducer;
